// src/App.js
import React, { useEffect, useState } from 'react';
import Calendar from './Components/Calendar';
import TimeChart from './Components/TimeChart';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './Assets/css/style.css';

function App() {
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const handleParentMessage = (event) => {
      if (event.data.type === 'PARENT_URL') {
        const parentUrl = event.data.url;

        const allowedDomain = 'https://crm.axcap.ae';
        if (parentUrl.startsWith(allowedDomain)) {
          setAuthorized(true);
        } else {
          setAuthorized(false);
        }
      }
    };

    window.addEventListener('message', handleParentMessage);

    return () => {
      window.removeEventListener('message', handleParentMessage);
    };
  }, []);

  if (!authorized) {
    return <div>Access Denied</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<TimeChart />} />
          <Route path='/calendar' element={<Calendar />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
