import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import { Spin } from 'antd';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TimeChart = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availability, setAvailability] = useState([]);
  const [viewMode, setViewMode] = useState(() => localStorage.getItem('viewMode') || 'bar');
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    return localStorage.getItem('selectedDate') || currentDate;
  });


  const formatDateForBitrix = (isoDate) => {
    const [year, month, day] = isoDate.split('-');
    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    localStorage.setItem('viewMode', viewMode);
  }, [viewMode]);

  useEffect(() => {
    localStorage.setItem('selectedDate', selectedDate);
    fetchAvailability(formatDateForBitrix(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchAvailability = async (date) => {
    let results = [];
    let start = 0;
    const IBLOCK_TYPE_ID = "lists_socnet";
    const IBLOCK_ID = 187;
    const FILTER = {
      "PROPERTY_915": 6837,
      ">PROPERTY_910": `${date} 00:00:00 am`,
      "<=PROPERTY_910": `${date} 11:59:59 pm`
    };

    const getElements = async (start) => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_TimeTracking, {
          "IBLOCK_TYPE_ID": IBLOCK_TYPE_ID,
          "IBLOCK_ID": IBLOCK_ID,
          "FILTER": FILTER,
          "start": start
        });

        results = results.concat(response.data.result);

        if (response.data.result.length < 50) {
          setAvailability(results);
          setLoading(false);
          return results;
        } else {
          return getElements(start + 50);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        return [];
      }
    };

    await getElements(start);
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_GetAllBitrixUsers);
      setEmployees(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const uniqueManagers = [...new Set(employees.map(employee => employee.manager))];

  const managerNames = uniqueManagers.map(id => {
    const manager = employees.find(employee => employee.value === id);
    return manager ? manager.label : `Manager ${id}`;
  });

  if (managerNames.length > 0) {
    managerNames[0] = 'Denis Axinoi';
  }

  const managerTeam = uniqueManagers.map(id => {
    const employeesUnderManager = employees.filter(employee => employee.manager === id);
    return { managerId: id, count: employeesUnderManager.length };
  });

  // Function to get property values
  const getPropertyValues = (property) => {
    if (property == null) {
      return [];
    }
    return Object.values(property);
  };

  const attendance = managerTeam.map(manager => {
    const employeesUnderManager = employees.filter(employee => employee.manager === manager.managerId);
    const presentEmployees = employeesUnderManager.filter(employee => {
      return availability.some(available => getPropertyValues(available.PROPERTY_912).includes(String(employee.value)));
    });

    const totalCount = employeesUnderManager.length;
    const presentCount = presentEmployees.length;
    const percentage = totalCount === 0 ? 0 : (presentCount / totalCount) * 100;
    return percentage;
  });

  const combinedArray = managerNames.map((name, index) => {
    return { name: name, proce: attendance[index], managerId: uniqueManagers[index] };
  });

  combinedArray.sort((a, b) => a.proce - b.proce);

  const sortedNames = combinedArray.map(item => item.name);
  const sortedApplesCollected = combinedArray.map(item => item.proce);
  const sortedManagerIds = combinedArray.map(item => item.managerId);

  // Create links based on manager IDs
  const links = sortedManagerIds.map(id => process.env.REACT_APP_API_URL + `/calendar/?id=${id}`);

  const data = {
    labels: sortedNames,
    datasets: [{
      label: 'Employee attendance percentage',
      data: sortedApplesCollected,
      backgroundColor: 'rgba(211, 161, 136, 0.2)',
      borderColor: 'rgba(211, 161, 136)',
      borderWidth: 1
    }]
  };

  const options = {
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        window.location.href = links[index];
      }
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.dataset.label || '';
            const value = context.raw;
            const roundedValue = Math.round(value * 100) / 100; // округление до двух знаков после запятой
            return `${label}: ${roundedValue}%`;
          }
        }
      }
    }    
  };

  const renderBarChart = () => (
    <div style={{ height: 'calc(100vh - 70px)' }} className="chart-container">
      <Bar data={data} options={options} />
    </div>
  );

  const countOccurrences = (array, property, targetValue) => {
    let targetCount = 0;
    let otherCount = 0;

    array.forEach(item => {
      const value = item[property] ? item[property][Object.keys(item[property])[0]] : null;
      if (value === targetValue) {
        targetCount += 1;
      } else {
        otherCount += 1;
      }
    });

    return { targetCount, otherCount };
  };

  const { targetCount, otherCount } = countOccurrences(availability, 'PROPERTY_918', 'outside');

  const renderProgressBars = () => (
    <div className="progress-container">
      <table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Manager</th>
            <th>%</th>
            <th>Attendance Progress</th>
          </tr>
        </thead>
        <tbody>
          {combinedArray.map((item, index) => (
            <tr key={index} className="progress-bar">
              <td>
                <a href={links[index]} target="_blank" rel="noopener noreferrer">{item.name}</a>
              </td>
              <td>
                <p className="proc">{Math.floor(item.proce)}%</p>
              </td>
              <td>
                <div style={{ width: '100%' }} className="total">
                  <div
                    className="progress-value"
                    style={{ width: `${item.proce}%` }}
                    onClick={() => window.open(links[index], '_blank')}
                  >
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  if (loading) {
    return <div>
      <Spin className="spin-styles" tip="Loading" size="large">
          {/* {content} */}
      </Spin>
    </div>;
  }

  return (
    <div style={{ height: '100vh' }} className="chart-container">
      <div className="filter-chart">
        <button onClick={() => setViewMode('bar')}>Chart</button>
        <button onClick={() => setViewMode('progress')}>Progress</button>
        <input 
          type="date" 
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <div className="info">
          <p>Total Clock-In: <span>{availability.length}</span></p>
          <p>Outside Clock-In: <span>{targetCount}</span></p>
          <p>In office Clock-In: <span>{otherCount}</span></p>
        </div>
      </div>
      {viewMode === 'bar' ? renderBarChart() : renderProgressBars()}
    </div>
  );
};

export default TimeChart;
